import {Component, OnInit} from '@angular/core';
import {DropletService} from '../../Services/Droplet.service';
import {InitProvider} from '../../init-provider';
import {CoursesService} from 'src/app/Services/Courses.service';
import {EventsService} from 'src/app/Services/events.service';
import * as moment from 'moment-timezone';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute} from '@angular/router';
import * as Player from '@vimeo/player/dist/player.js';

@Component({
    selector: 'app-home',
    templateUrl: './Home.component.html',
    styleUrls: ['./Home.component.scss']
})
export class HomeComponent implements OnInit {
    gridOverlay: any;
    serviceItems: any;
    testimonialData: any;
    counterContent: any;
    pricingContent: any;
    company: any;
    courses = [];
    events = [];
    Math = Math;
    whatsapp_number = '';
    course_text = 'Course';
    lesson_text = 'Unit';
    course_text_s;
    lesson_text_s;
    show_lesson_text;
    view_plans_logout = false;
    hideFreePlanInHome = false;
    slider = [];
    video = null;
    videoLoaded = false;
    videoId = null;

    constructor(
        public service: DropletService,
        public initProvider: InitProvider,
        public coursesService: CoursesService,
        public eventsService: EventsService,
        private route: ActivatedRoute,
        private toastr: ToastrService,
    ) {
        this.company = initProvider.getSettings();
        this.whatsapp_number = this.company.whatsapp_number;
    }

    ngOnInit() {
        this.getPublicCourses();
        this.getPublicEvents();
        this.view_plans_logout = this.company.view_plans_logout;

        if (this.company.home_video) {
            this.coursesService
                .getVideoData(this.company.home_video.url)
                .then((data) => {
                    this.videoId = data.body?.uri?.replace('/videos/', '');
                })
                .catch((error) => {
                    console.log(error);
                });
        }


        const json = JSON.parse(this.company.faq_json);
        if (json && json.hide_free_plan_in_home) {
            this.hideFreePlanInHome = true;
        }
        let company_language = this.company.language;
        this.show_lesson_text = json.configuration ? json.configuration.show_lessons_in_courses : 'true';
        //console.log(json.texts.course);
        if (company_language == 'en') {
            this.course_text = json.texts ? json.texts.course_en : 'Course';
            this.lesson_text = json.texts ? json.texts.lesson_en : 'Unit';
        }
        if (company_language == 'es') {
            this.course_text = json.texts ? json.texts.course_es : 'Course';
            this.lesson_text = json.texts ? json.texts.lesson_es : 'Unit';

        }
        if (company_language == 'it') {
            this.course_text = json.texts ? json.texts.course_it : 'Course';
            this.lesson_text = json.texts ? json.texts.lesson_it : 'Unit';

        }
        this.course_text_s = this.course_text + 's';
        this.lesson_text_s = this.lesson_text + 's';
        this.route.queryParams.subscribe(params => {
            if (params.expired) {
                this.toastr.error('Su sesión ha expirado. Por favor inicie sesión nuevamente.');
            }
        });


        for (let i = 1; i < 6; i++) {
            if (
                this.company['home_slider_title_' + i]
                || this.company['home_slider_description_' + i]
                || (this.company['home_slider_image_' + i] && !this.imageIsPlaceholder(this.company['home_slider_image_' + i]))
            ) {
                this.slider.push({
                    title: this.company['home_slider_title_' + i],
                    description: this.company['home_slider_description_' + i],
                    image: this.company['home_slider_image_' + i]
                });
            }
        }
        if (this.company.home_video?.url) {
            this.video = 'https://player.vimeo.com/video/' + this.company.home_video.url.substr(8) + '?embedparameter=value';
            console.log(this.video, 'video');
        }

    }

    getPublicCourses() {
        this.coursesService.getPublicCourses().then((courses) => {
            this.courses = courses.data;
        }).catch((error) => {
            console.log(error);
        });
    }

    getPublicEvents() {
        this.eventsService.getPublicListWithFilters('from_end_date=2021-02-11%2016:53&order_name=start_date&order_type=ASC').then((events) => {
            this.events = events.data;
        }).catch((error) => {
            console.log(error);
        });

    }

    parseEventDateNumber(event) {
        let date = event.start_date.date;
        let yourDate = new Date(date + ' UTC');
        date = yourDate.toString();
        return moment(date).date();
        //return moment(event.start_date.date).date();
    }

    parseEventMonth(event) {

        let date = event.start_date.date;
        let yourDate = new Date(date + ' UTC');
        date = yourDate.toString();
        //return moment(date).format('HH:mm');
        return moment(date).locale(this.company.language).format('MMM').replace('.', '');
    }

    parseEventStartTime(event) {
        let date = event.start_date.date;
        let yourDate = new Date(date + ' UTC');
        date = yourDate.toString();
        return moment(date).format('HH:mm');
    }

    parseEventEndTime(event) {
        let date = event.end_date.date;
        let yourDate = new Date(date + ' UTC');
        date = yourDate.toString();
        return moment(date).format('HH:mm');
    }

    isDiscounted(course) {
        return course.discount_has && moment(course.discount_until) > moment();
    }

    initializeVideoPlayer(videoID) {
        const player = new Player(videoID, {
            id: videoID,
            responsive: true
        });

        player.on('ended', () => {
            //
        });

        player.on('loaded', () => {
            const padding = player.element.parentNode.style.paddingTop.replace('%', '');
            if (padding > 75) {
                player.element.parentNode.style.paddingTop = '75%';
            }
            this.videoLoaded = true;
        });

        $('#modal-video').on('hide.bs.modal', () => {
            player.unload();
            this.videoLoaded = false;
        });
    }

    openVideoModal(videoID) {
        this.videoLoaded = true;

        setTimeout(() => {
            this.initializeVideoPlayer(videoID);
        }, 200);
    }

    getColorRgba(hexadecimal) {
        const hexToRgb = hexadecimal.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
            , (m, r, g, b) => '#' + r + r + g + g + b + b)
            .substring(1).match(/.{2}/g)
            .map(x => parseInt(x, 16));
        return `rgba(${hexToRgb[0]},${hexToRgb[1]},${hexToRgb[2]},0.7)`;
    }

    imageIsPlaceholder(image) {
        return image.small.includes('generic');
    }
}
