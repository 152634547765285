<a *ngIf="whatsapp_number" target="_blank" [attr.href]="'https://wa.me/' + whatsapp_number" class="btn btn-round btn-fab btn-fab__wapp">
	<img src="assets/images/whatsapp.png" alt="whatsapp" loading="lazy" decoding="async" width="68" height="68">
</a>
<div [dir]="isRtl" (scroll)="onScrollEvent($event)" class="app-wrapper">
	<app-header-one></app-header-one>
   <!-- header close -->
	<ng-container *ngIf="currentUrl=='/home'">
	  <!--   <app-home-banner></app-home-banner>
	    banner sec close -->
	</ng-container>
    <!-- rtl Layout close  -->
	<router-outlet (activate)="onActivate($event)"></router-outlet>
	<ng-container >
		<app-footer-one></app-footer-one>
	</ng-container>
   <!-- footer close-->
</div>
<!-- rtl wrap close -->
