import {Component, OnInit, Input} from '@angular/core';
import {ApiService} from '../../Services/Api.service';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/Services/Auth/auth.service';
import {InitProvider} from '../../init-provider';
import {ActivatedRoute} from '@angular/router';
import 'rxjs/add/operator/filter';
import _ from 'lodash';

@Component({
  selector: 'app-pricing-list',
  templateUrl: './pricing-list.component.html',
  styleUrls: ['./pricing-list.component.scss']
})
export class PricingListComponent implements OnInit {

  @Input() showTrial: any;
  @Input() logged: string = "true";

  plans = [];
  user: any = {};
  errName = false;
  errEmail = false;
  errDni = false;
  saveLoading = false;
  currentUser;
  plan
  plan_select = null;
  plan_id = null;
  plan_name = '';
  plan_price: '';
  plan_include: '';
  plan_interval: '';
  plan_interval_count: '';
  view_free = false;
  plan_access = '';
  course_text = "Course";
  lesson_text = "Unit";
  course_text_s;
  lesson_text_s;
  company: any;
  free_plan = 'free_include';

  constructor(private apiService: ApiService,
              public authService: AuthService,
              public initProvider: InitProvider,
              private router: Router,
              private route: ActivatedRoute) {
    // console.log('this is profile!', this.plans, this.showTrial, this);


  }

  ngOnInit() {
    let user = this.authService.user;
    this.currentUser = user;

    console.log('User');
    console.log(user);
    this.user = JSON.parse(localStorage.getItem('user'));
    console.log('ng on init! pricing', this.showTrial);
    this.company = this.initProvider.getSettings();
    this.get();
  }

  get() {
    this.apiService.get('plans?active=true').then((res) => {
      this.transform(res.data);
      this.transformTextAccessPlan(res.data);
      this.plans = res.data;
      // this.plan_access=res.data.access.name;

      this.route.queryParams
        .subscribe(params => {
            if(params && params.plan){
              const plan = _.find(this.plans,(item)=>{
                 return params.plan == item.key;
              });
              if(plan)
                this.selectPlan(plan);
            }
          }
        );
    });
  }

  transform(plans) {

    plans.forEach((plan) => {
      if (this.user !== null && this.user.plan !== null && plan.id === this.user.plan.id) {
        plan.selected = true;
        plan.selecting = false;

      }
    });

  }

  transformTextAccessPlan(plans) {
    const json = JSON.parse(this.company.faq_json);
    let company_language = this.company.language;
    //console.log(json.texts.course);
    if (company_language == "en") {
      this.course_text = json.texts ? json.texts.course_en : 'Course';
      this.lesson_text = json.texts ? json.texts.lesson_en : 'Unit';
    }
    if (company_language == "es") {
      this.course_text = json.texts ? json.texts.course_es : 'Course';
      this.lesson_text = json.texts ? json.texts.lesson_es : 'Unit';

    }
    if (company_language == "it") {
      this.course_text = json.texts ? json.texts.course_it : 'Course';
      this.lesson_text = json.texts ? json.texts.lesson_it : 'Unit';

    }

    this.course_text_s = this.course_text + 's';
    this.lesson_text_s = this.lesson_text + 's';
    if (this.course_text == 'Course') return; //si no tiene cambios en el json
    this.free_plan = 'Todos los Senderos y Eventos gratuitos';

    plans.forEach((plan) => {
      console.log('plan');
      console.log(plan.access.name);
      plan.access.name = plan.access.name.replace('courses', this.course_text_s);
      plan.access.name = plan.access.name.replace('categories', this.lesson_text_s);


    });

  }

  selectPlan(plan) {
    if (this.logged == 'false') {
      $('#modal-register').modal('show');
      console.log(plan);
      this.plan_id = plan.id;
      this.plan_name = plan.name;
      this.plan_price = plan.price_description;
      this.plan_include = plan.access.name;
      this.plan_interval_count = plan.interval_count;
      this.plan_interval = plan.interval;

      this.plan_select = plan;
      return;
    }
    if (plan.selected || plan.price === 0) {
      return;
    }
    console.log('Plans');
    console.log('Datos del usuario');
    let user = this.authService.user;
    this.currentUser = user;
    console.log(this.currentUser);

    if (user.company.request_billing_info) {
      console.log('Company necesita datos de facturación');
      if (!user.invoice_name || !user.invoice_email || !user.invoice_dni || !user.phone_number_invoice) {
        console.log('Falta un dato');
        $('#modal-timezone').modal('show');
        this.plan = plan;
        return;
      }
    }
    this.router.navigate(['/logged/profile/payment'], {queryParams: {plan: plan.id}});

  }


  async onSaveProfile() {

    if (this.plan.selected || this.plan.price === 0) {
      return;
    }
    console.log('saved this22!!!', this);
    this.saveLoading = true;
    this.errName = false;
    this.errEmail = false;
    this.errDni = false;
    if (!this.currentUser.invoice_name) {
      console.log('no tiene nombre');
      this.errName = true;
    }

    if (!this.currentUser.invoice_dni) {
      this.errDni = true;
    }

    if (!this.currentUser.invoice_email) {
      this.errEmail = true;
    }

    if (this.errEmail || this.errDni || this.errName) {
      return;
    }
    const saved = await this.apiService.put('users/' + this.currentUser.id, {

      invoice_name: this.currentUser.invoice_name,
      invoice_dni: this.currentUser.invoice_dni,
      invoice_address: this.currentUser.invoice_address,
      invoice_giro: this.currentUser.invoice_giro,
      invoice_razon_social: this.currentUser.invoice_razon_social,
      invoice_email: this.currentUser.invoice_email,

    }).catch(err => err);

    if (saved.error) {
      if (saved.error.errors.email) {
        return alert(saved.error.errors.email[0]);
      } else if (saved.error.errors.first_name) {
        return alert(saved.error.errors.first_name[1]);
      } else if (saved.error.errors.last_name) {
        return alert(saved.error.errors.last_name[1]);
      }
    }
    console.log('aaa saved!', saved);
    console.log(saved);
    this.authService.saveUserData();
    this.saveLoading = false;
    $('#modal-timezone').modal('hide');
    this.router.navigate(['/logged/profile/payment'], {queryParams: {plan: this.plan.id}});

  }
}
