<picture>
<!--  <source [srcset]="webp" type="image/webp" *ngIf="webp">-->
  <source [srcset]="jpg" type="image/jpeg" *ngIf="jpg">
  <source [srcset]="png" type="image/png" *ngIf="png">
  <img [src]="src" [alt]="alt ? alt : 'Alt'" *ngIf="width && height"
       decoding="async" [attr.loading]="loading"
       [attr.width]="width"
       [attr.height]="height"
  >
</picture>
