<header class="cover pb-5">
    <div class="cover--imageBackground">
      <img [src]="company.sign_up_image.large" [srcset]="company.sign_up_image | srcset" sizes="100vw" loading="eager" decoding="async" alt="">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-5 mb-3 mt-5">
                <div class="row">
                    <div class="col-12 p-4 position-relative">
                        <div class="transparent-bg d-none d-md-block"></div>
                        <div class="row">
                            <div class="col-12 col-md-10">
                                <h3 class="cover--title text-primary titleTextFont">{{ company.register_text_title}}</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <p class="cover--description text-secondary">{{ company.register_text_description }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 offset-lg-3">
                <app-signup-form></app-signup-form>
            </div>
        </div>
    </div>
  </header>
