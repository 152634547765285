import {Component, Input, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {InitProvider} from '../../init-provider';


@Component({
  selector: 'app-thanks-buy',
  templateUrl: './ThanksBuy.component.html',
  styleUrls: ['./ThanksBuy.component.scss']
})
export class ThanksBuyComponent implements OnInit {

  @Input() title: String;
  @Input() msg: String;
  @Input() imagen: String;
  @Input() subTitle: String;
  @Input() msgBtn: String;
  @Input() url: any;
  @Input() img: any;

  constructor(public location: Location,
              private router: Router,
              public initProvider: InitProvider,
  ) {

  }

  ngOnInit() {
    // const gaScript = document.createElement('script');
    // gaScript.innerHTML = `
    //   gtag('event', 'conversion', { 'send_to': 'G-2KSXW7MYHG', 'transaction_id': 'test' });
    // `;
    // document.head.appendChild(gaScript);
  }

  redirectTo(uri: string) {
    this.router.navigate(['/']);
    this.router.navigate([uri]);
  }

  goBtn() {
    console.log('BTN press');
    console.log(this.title);
    console.log(this.url);
    this.location.replaceState(this.url, '');
    window.location.reload();
    // this.router.navigate([this.url])

  }

}
