export const environment = {
  production: false,
  apiUrl: 'https://admin.cte.staging.educativa.es/api/v1/',
  baseUrl: 'https://admin.cte.staging.educativa.es/',
  apiKeyMap: 'AIzaSyABNf-IQEYMiot3cFI_Ele5uW8ig8DW8FA',
  paymentsUrl: 'http://walleq.io/',
  auth: {
    clientSecret: 'xGbAhWL960HycCoboFNvKdhpWJxnLdxIs55Gjn95',
    clientId: '2'
  }
};


