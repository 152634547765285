import { Component, Input, OnInit, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-sub-header",
  templateUrl: "./sub-header.component.html",
  styleUrls: ["./sub-header.component.scss"],
})
export class SubHeaderComponent implements OnInit {
  @Input() logo: any = "";
  @Input() nameCourse: any = "";

  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    this.logo;
    this.nameCourse;
    
  }
}
