import {Component, EventEmitter, OnInit, Input, Output} from '@angular/core';
import { AuthService } from 'src/app/Services/Auth/auth.service';
import { CoursesService } from 'src/app/Services/Courses.service';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/Api.service';

@Component({
  selector: 'app-reviewform',
  templateUrl: './review-form.component.html',
  styleUrls: ['./review-form.component.scss']
})
export class ReviewFormComponent implements OnInit {
  @Input() extra = null;
  @Input() courseId = null;
  @Input() course = null;
  @Output() onContinue = new EventEmitter<string>();
  user;
  niveles = [];
  liked=0;
  difficulty=0;

  constructor(public authService: AuthService, public courseService: CoursesService, private router: Router
    , private apiService: ApiService) {
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (!this.course) {
      this.courseService.getCourseById(this.courseId).then((courseData) => {
        this.course = courseData.data;
        this.course.lessons.data = this.course.lessons.data.map((course) => {
          return { ...course, expand: false };
        });



      }).catch((error) => {
        console.log(error);
        this.router.navigate(['home']);
      });
    } else {
      this.course.lessons.data = this.course.lessons.data.map((course) => {
        return { ...course, expand: false };
      });


    }
  }


  continue() {
      this.difficulty=0;
      this.liked=0;
      $(".modal-backdrop").remove();
      this.onContinue.emit();
  }

  async onSubmit(form) {
    const reviewToStore = {
      course: this.course.id,
      liked: this.liked,
      difficulty: this.difficulty
    }
    try {
      const response = await this.courseService.sendCourseReview(reviewToStore);
      this.continue();
    } catch (error) {
      console.log(error);
    }
  }
}
