<div class="container-fluid topTitle d-none">
    <div class="container">
        <div class="row">
            <div class="col-md-8 offset-md-2 text-center title onResponsiveTop">
                <h2 class="pageTitle">¿Tienes alguna pregunta?</h2>
                <p class="mt-2">Seleccione un video de ayuda</p>
                <button class="btn btn-info" data-toggle="collapse" data-target="#videos">Ver Videos</button>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row videos collapse" id="videos">
        <div class="col-xl-10 offset-xl-1 mt-3">
            <nav class="text-center" data-toggle="modal" [attr.data-target]="'#modal-faq'">
                <a class="text-left" (click)="openVideoModal('424144231')">
                    <div class="thumbContainer">
                        <img src="assets/images/mootiva/logo-dark.png" width="540" height="350" decoding="async" loading="lazy">
                        <div class="playContainer">
                            <img class="playButton" src="assets/images/lesson/play.svg" width="16" height="16" decoding="async" loading="lazy">
                        </div>
                    </div>
                    <p class="mt-2 font-weight-bold">Qué es Mootiva?</p>
                </a>
                <a class="text-left" (click)="openVideoModal('424144231')">
                    <div class="thumbContainer">
                        <img src="assets/images/mootiva/logo-dark.png" width="540" height="350" decoding="async" loading="lazy">
                        <div class="playContainer">
                            <img class="playButton" src="assets/images/lesson/play.svg" width="16" height="16" decoding="async" loading="lazy">
                        </div>
                    </div>
                    <p class="mt-2 font-weight-bold">Cómo definir<br/>tus intereses?</p>
                </a>
                <a class="text-left" (click)="openVideoModal('404622580')">
                    <div class="thumbContainer">
                        <img src="assets/images/mootiva/logo-dark.png" width="540" height="350" decoding="async" loading="lazy">
                        <div class="playContainer">
                            <img class="playButton" src="assets/images/lesson/play.svg" width="16" height="16" decoding="async" loading="lazy">
                        </div>
                    </div>
                    <p class="mt-2 font-weight-bold">Preguntas<br/>frecuentes</p>
                </a>
                <a class="text-left" (click)="openVideoModal('424144231')">
                    <div class="thumbContainer">
                        <img src="assets/images/mootiva/logo-dark.png" width="540" height="350" decoding="async" loading="lazy" >
                        <div class="playContainer">
                            <img class="playButton" src="assets/images/lesson/play.svg" height="16" width="16" decoding="async" loading="lazy">
                        </div>
                    </div>
                    <p class="mt-2 font-weight-bold">Como comunicarte<br/>con un asesor?</p>
                </a>
                <a class="text-left" (click)="openVideoModal('424144231')">
                    <div class="thumbContainer">
                        <img src="assets/images/mootiva/logo-dark.png" width="540" height="350" decoding="async" loading="lazy">
                        <div class="playContainer">
                            <img class="playButton" src="assets/images/lesson/play.svg" width="16" height="16" decoding="async" loading="lazy">
                        </div>
                    </div>
                    <p class="mt-2 font-weight-bold">Cómo realizar<br/>un test?</p>
                </a>
            </nav>
        </div>
    </div>

    <div class="row mt-5 mb-2 pt-5">
        <div class="col-lg-6 col-md-10 offset-lg-3 offset-md-1">
            <img [src]="image_top?image_top:'assets/images/mootiva/globes.png'" src="assets/images/mootiva/globes.png" width="1208" height="266" decoding="async" loading="lazy">
        </div>
    </div>

    <div class="row mt-5 mb-4" *ngIf="faqs.length > 0">
        <div class="col">
            <h3 class="titleTextFont text-center font-weight-normal">{{ "Faqs" | translate }}</h3>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-8 offset-lg-2">
            <app-faq-item *ngFor="let faq of faqs" [title]="faq.title"
            [collapsed]="faq.collapsed"
            (boxExpanded)="setAllClosed($event)"
            [content]="faq.content"> </app-faq-item>
        </div>
    </div>

    <div class="row position-relative mt-5 mb-5">
        <div class="contactBg">
            <img [src]="image_bottom?image_bottom:'assets/images/mootiva/contact-bg.png'" width="615" height="781" decoding="async" loading="lazy">
        </div>
        <div class="col-md-6 col-lg-4 offset-md-6">
            <form class="contactForm" #contactForm="ngForm"  (ngSubmit)="onSubmit(contactForm)">
                <!-- <div class="form-group">
                    <img src="assets/images/mootiva/user.svg">
                    <input class="form-control contactInput" name="name" type="text" placeholder="Nombre Apellido" required ngModel >
                    <span class="danger" *ngIf="showError(contactForm, 'name')"> This field is required </span>
                </div>
                <div class="form-group">
                    <img src="assets/images/mootiva/mail.svg">
                    <input class="form-control contactInput" name="email" type="email" placeholder="Email" required ngModel >
                    <span class="danger" *ngIf="showError(contactForm, 'email')"> This field is required </span>
                </div> -->
                <div class="form-group">
                    <img src="assets/images/mootiva/question.svg" width="18" height="21" decoding="async" loading="lazy">
                    <textarea class="form-control contactInput" name="question" rows="5" placeholder=" {{'Ask us a question or comment'|translate}} "  required ngModel ></textarea>
                    <span class="danger" *ngIf="showError(contactForm, 'question')"> Este campo es obligatorio </span>
                </div>
                <div class="d-flex justify-content-end mt-4">
                    <button class="planeBtn d-flex justify-content-center align-items-center push-right" [disabled]="sendingStatus.loading"><img class="mb-1" src="assets/images/mootiva/plane-violet.svg"></button>
                </div>
                <div class="status">
                    <span class="danger" *ngIf="sendingStatus.message && sendingStatus.error" [innerHTML]="sendingStatus.message"> </span>
                    <span class="success" *ngIf="sendingStatus.message && !sendingStatus.error" [innerHTML]="sendingStatus.message"> </span>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" [id]="'modal-faq'" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content" [class.darken]="videoLoaded" style="border-radius: 10px; background:black">
            <div class="modal-header" style="background: black; border: 0">
                <button #closeModal style="color: white" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>

            </div>
            <div class="modal-body text-center">
                <img *ngIf="!videoLoaded" class="img-fluid" style="margin: auto;" src="assets/images/loading.gif"
                width="400" height="300" decoding="async" loading="lazy">
                <div

                class="playerWrap"
                 *ngIf="videoId"
                 [id]="videoId">
                <iframe [src]="videoUrl" *ngIf="showVideo()"  id="videoFrame" width="100%" height="564" frameborder="0" loading="lazy"
                    allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
