<div class="container">
  <div class="row mb-3 justify-content-between py-3 background-header">
    <div class="col-md-10 col-8 my-auto">
      <h5>Inscripción a {{nameCourse}}</h5>
    </div>
    <div class="col-md-2 col-4 justify-content-end d-flex">
      <picture>
        <source media="(max-width: 799px)" srcset="" />
        <img
          *ngIf="logo?.medium"
          class="img-title"
          [src]="logo?.medium"
          onerror=""
        />
      </picture>
    </div>
  </div>
</div>
