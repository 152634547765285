import {Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-contest-participant',
  templateUrl: './contest-participant.component.html',
  styleUrls: ['./contest-participant.component.scss']
})
export class ContestParticipantComponent implements OnInit {
  @Input() participant: any;
  @Input() canLeave = false;
  @Input() canVote = false;
  @Input() votedByMe = false;
  @Input() isFinished = false;
  @Input() show = true;
  @Input() waitingForApproval = false;
  @Input() podium = 0;
  @Output() vote = new EventEmitter();
  @Output() leave = new EventEmitter();
  @ViewChild('lightboxImage', { static: false }) imgLightboxImage: ElementRef;
  @ViewChild('lightbox', { static: false }) divLightboxImage: ElementRef;


  constructor() {
  }

  ngOnInit(): void {
  }

  emitLeave() {
    this.leave.emit();
  }

  emitVote(id: ArrayBufferView | ArrayBuffer) {
    this.vote.emit(id);
  }

  imageIsPlaceholder(image) {
    return image.small.includes('generic');
  }

  changeLightboxImage(image: any) {
    this.imgLightboxImage.nativeElement.src = image;
    this.divLightboxImage.nativeElement.classList.add('show');
  }

  closeLightbox() {
    this.imgLightboxImage.nativeElement.src = '';
    this.divLightboxImage.nativeElement.classList.remove('show');
  }
}
