<div class="container mb-5" >  
    <div class="row">
        <div class="col-12 text-center">
            <h3  class="msgText mt-4 titleTextFont">{{title}}</h3>
        </div>
    </div>
   <div class="row text-center">
    <div class="col-12">
        <img class="img" [src]="'assets/images/'+img">          

    </div>
   </div> 
   <div class="row ">
    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-5  mx-auto msgText1">
       {{subTitle}}
    </div>
</div>
   <div class="row ">
       <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-4  mx-auto msgText2">
       {{msg}}
       </div>
   </div>
   <div class="row mt-2 btnResp" *ngIf="url">
       <div class="col-12 col-lg-8 offset-lg-2 mt-2">
           <a  (click)="goBtn()" class="btn btn-info btn-course"> {{msgBtn}}</a>
       </div>
   </div>
  
</div>