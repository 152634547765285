<div *ngFor="let plan of plans">
  <div [class.selected]="plan.selected" *ngIf="showTrial==='show' ||  plan.price > 0" class="row planContainer mb-3">
    <div class="col-md-4 col-lg-4 itemBox">

      <img class="img-hero" [src]="plan.image.small" loading="lazy" decoding="async" [srcset]="plan.image | srcset">
      <div *ngIf="plan.selected" class="title-img">{{"Your current plan"|translate}}</div>

      <!--<div class="discountBox mt-4">{{ plan.discount_description }}</div>-->
      <!-- <div *ngIf="plan.discount" class="discountBox "> <i class="fa fa-star"></i> SUPER DESCONTO!-->
      <!-- </div>-->
      <!-- <div *ngIf="plan.discount_description!=''" class="planTitle">{{ plan.name }}</div>-->
    </div>

    <div class="col-md-4 col-lg-6 overflow-auto">
      <div class="row titleDescription">
        <div *ngIf="plan.discount_description!=''" class="planTitle ">{{ plan.name }}</div>
      </div>
      <div class="row mt-3">
        <span class="description-include"> <strong>{{"Include"|translate}}
          :</strong> {{(plan.price == 0 ? free_plan : plan.access.name)  |translate}}</span>
        <span class="description-include"
              *ngFor="let categ of plan.access_categories_all;let primero = first;let ultimo = last;"
        >{{(primero ? ' - ' : '')}}{{categ.name}}{{(ultimo ? '' : ', ')}}
            </span>
      </div>
      <div class="row mt-2">
        <p class="description " [innerHTML]="plan.description2"></p>
      </div>


    </div>

    <div class="col-md-4 col-lg-2 ammount d-flex flex-column justify-content-around align-items-center svg-image">


      <span *ngIf="plan.interval=='day'"
            class="interval-time">{{plan.interval_count}} {{ (plan.interval_count == 1) ? ('Day'|translate) : ('Days'|translate)}}</span>
      <span *ngIf="plan.interval=='week'"
            class="interval-time">{{plan.interval_count}} {{ (plan.interval_count == 1) ? ('Week'|translate) : ('Weeks'|translate)}}</span>
      <span *ngIf="plan.interval=='year'"
            class="interval-time">{{plan.interval_count}} {{ (plan.interval_count == 1) ? ('Year'|translate) : ('Years'|translate)}}</span>
      <span *ngIf="plan.interval=='month'"
            class="interval-time">{{plan.interval_count}} {{ (plan.interval_count == 1) ? ('Month'|translate) : ('Months'|translate)}}</span>

      <div class="price">
        <p class="total" [innerHTML]="plan.price_description"></p>
      </div>

      <img class="icon-img" [src]="plan.icon.small" decoding="async" loading="lazy" [srcset]="plan.icon | srcset">
      <!-- <i *ngIf="plan.price==0" class="fas fa-gift icons"></i>
      <i *ngIf="plan.price>0" class="fas fa-user-graduate icons"></i> -->


      <div *ngIf="((plan.selected) || plan.price >= 0)" class="plan-button mb-4 pointer"
           [class.pointer]="!plan.selected" (click)="selectPlan(plan)">
        <span class="selected" *ngIf="plan.selected && !plan.selecting"> {{"Selected"|translate}} </span>
        <span class="select" *ngIf="!plan.selected && !plan.selecting && logged=='true'">{{"Select"|translate}}  </span>
        <span class="select"
              *ngIf="!plan.selected && !plan.selecting && logged=='false'">{{"Subscription"|translate}}  </span>
        <span class="select" *ngIf="plan.selecting">{{"Selected"|translate}}  <i
          class="fas fa-circle-notch fa-spin"></i></span>
      </div>


    </div>
  </div>
</div>


<div class="row border-top"></div>

<app-invoice-modal type="Plan" [ID]="plan?.id"></app-invoice-modal>
<!--Modal add data billing-->
<!--
<div class="modal fade " id="modal-timezone" tabindex="-1" role="dialog" aria-labelledby="Aviso Legal">
    <div class="modal-dialog-width modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modal-timezone" style="border-radius: 20px;">

            <div class="modal-body">
                        <div class="row justify-content-end mr-1">
                            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="container">
                            <div class="row">
                                <div class="col-12 mt-1 text-center onResponsiveTop mb-5">
                                    <h2 class="text-info text-info-timezone">{{ "Hello" | translate }} <span class="text-info text-info-timezone">{{ authService.user.first_name }}!</span></h2>

                                        <div *ngIf="currentUser.company.request_billing_info">

                                            <div class="row mt-3 no-padding">
                                                <div class="col-12 center-title" style="padding-top: 10px;"> {{ 'Please complete the billing information' | translate}} </div>

                                            </div>

                                            <div class="row mt-3 no-padding">
                                                <div class="col-4 leftInfoTitle" style="padding-top: 10px;"> {{ 'Fullname' | translate}} </div>
                                                <div class="col-8 rightInfoText no-padding">
                                                    <input type="text" style="width: 100%;" [(ngModel)]="currentUser.invoice_name" class="lumi-input-text"

                                                        />
                                                    <span class="help is-danger" *ngIf="errName">Por favor complete el nombre </span>
                                                </div>
                                            </div>
                                            <div class="row mt-3 no-padding">
                                                <div class="col-4 leftInfoTitle" style="padding-top: 10px;"> {{ 'DNI/RUT' | translate}} </div>
                                                <div class="col-8 rightInfoText no-padding">
                                                    <input type="text" style="width: 100%;" [(ngModel)]="currentUser.invoice_dni" class="lumi-input-text"

                                                        />
                                                        <span class="help is-danger" *ngIf="errDni">{{'Please complete the DNI / RUT' | translate}} </span>

                                                </div>
                                            </div>
                                            <div class="row mt-3 no-padding">
                                                <div class="col-4 leftInfoTitle" style="padding-top: 10px;"> {{ 'Invoice Email' | translate}} </div>
                                                <div class="col-8 rightInfoText no-padding">
                                                    <input type="text" style="width: 100%;" [(ngModel)]="currentUser.invoice_email" class="lumi-input-text"

                                                        />
                                                        <span class="help is-danger" *ngIf="errEmail">{{'Por favor complete el Email' | translate}}</span>

                                                </div>
                                            </div>

                                            <div class="row mt-3 no-padding">
                                                <div class="col-4 leftInfoTitle" style="padding-top: 10px;"> {{ 'Business name' | translate}} </div>
                                                <div class="col-8 rightInfoText no-padding">
                                                    <input type="text" style="width: 100%;" [(ngModel)]="currentUser.invoice_razon_social" class="lumi-input-text"

                                                        />
                                                </div>
                                            </div>
                                            <div class="row mt-3 no-padding">
                                                <div class="col-4 leftInfoTitle" style="padding-top: 10px;"> {{ 'Giro (only for companies)' | translate}} </div>
                                                <div class="col-8 rightInfoText no-padding">
                                                    <input type="text" style="width: 100%;" [(ngModel)]="currentUser.invoice_giro" class="lumi-input-text"

                                                        />
                                                </div>
                                            </div>
                                            <div class="row mt-3 no-padding">
                                                <div class="col-4 leftInfoTitle" style="padding-top: 10px;"> {{ 'Billing Address (only for companies)' | translate}} </div>
                                                <div class="col-8 rightInfoText no-padding">
                                                    <input type="text" style="width: 100%;" [(ngModel)]="currentUser.invoice_address" class="lumi-input-text"

                                                        />
                                                </div>
                                            </div>

                                            <div class="col-md-12 row mt-5 no-padding" style="display: inline-block;  background: transparent; ">
                                                <button class="btn btn-info" (click)="onSaveProfile()">
                                                        {{"Save"|translate}}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
            </div>
        </div>
    </div>
  </div>
-->


<div class="modal fade " id="modal-register" tabindex="-1" role="dialog" aria-labelledby="Aviso Legal">
  <div class="modal-dialog-width modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-register" style="border-radius: 20px;">
      <div class="modal-body">
        <div class="row justify-content-end mr-1">
          <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 text-cter onResponsiveTop ">
              <app-signup-widget-form [plan_price]="plan_price"
                                      [plan_name]="plan_name"
                                      [plan_include]="plan_include"
                                      [plan_interval]="plan_interval"
                                      [plan_interval_count]="plan_interval_count"
                                      [plan]="plan_select"
                                      [idPlan]="plan_id"
              ></app-signup-widget-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
