import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CoursesService } from 'src/app/Services/Courses.service';
import { DomSanitizer } from '@angular/platform-browser';
declare var $;
import { AuthService } from 'src/app/Services/Auth/auth.service';

import { InitProvider } from '../../init-provider';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {

  submitted = false;
  sendingStatus = {
    loading: false,
    message: null,
    error: false,
    submitted: false,
  };

  videoId = '383643119';
  videoLoaded = false;
  isVideoShown = false;
  videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/' + this.videoId);
  faqs = [];
  image_top='';
  image_bottom='';

  currentUser = this.authService.user;

  constructor(
    private coursesService: CoursesService,
    public authService: AuthService,
    public sanitizer: DomSanitizer,
    public initProvider: InitProvider
  ) {
    const company = this.initProvider.getSettings();


    
      this.image_top=company.help_top_image.large
      this.image_bottom=company.help_bottom_image.large
       //console.log('company');
       //console.log(this.image_top);
       //console.log(this.image_bottom);



    const json = JSON.parse(company.faq_json);
    if (json.faqs) {
      this.faqs = json.faqs.map( (faq) => {
        let actual = faq;
        actual.collapsed = false;
        return actual;
      });
    }
  }

  ngOnInit() {
  }

  setAllClosed (title) {
    this.faqs = this.faqs.map((faq) => {
      return {...faq, collapsed: faq.title == title}
    });
  }

  onSubmit(contactForm: NgForm) {
    this.sendingStatus.submitted = true;
    this.sendingStatus.message = null;
    console.log('contact formm', this.currentUser, contactForm.value);
    if (contactForm.invalid) {
      return;
    }
    this.sendingStatus.loading = true;

    contactForm.value.name = this.currentUser.first_name + ' ' + this.currentUser.last_name;
    contactForm.value.email = this.currentUser.email;

    this.coursesService.sendContactForm(contactForm.value).then(() => {
      this.sendingStatus.loading = false;
      this.sendingStatus.message = 'Message was sent';
      this.sendingStatus.error = false;
      this.sendingStatus.submitted = false;

      contactForm.resetForm();
    }).catch((error) => {
      this.sendingStatus.loading = false;
      this.sendingStatus.error = true;
      this.sendingStatus.message = 'Error sending message. Please try again later';
      this.sendingStatus.submitted = false;
    });
  }

  showError(form: NgForm, fieldName) {
    if (!this.sendingStatus.submitted) {
      return false;
    }
    return form.control.get(fieldName) && !form.control.get(fieldName).valid;
  }


  openVideoModal (videoId) {
    this.videoId = videoId;
    setTimeout(() => {
      this.isVideoShown = false;
      this.videoLoaded = true;
      this.initializeVideoPlayer(videoId)
    }, 200);
  }

  initializeVideoPlayer(videoId) {
    this.videoId = videoId;
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/' + this.videoId)
    $("#modal-faq").on("hide.bs.modal", () => {
      this.videoId = null;
      this.videoUrl = null;
      try {
        document.getElementById('videoFrame').remove()
      } catch (error) {

      }

    });
  }

  showVideo() {
    return this.videoId && this.videoUrl;
  }
}
