<footer class="p-3" id="footer-wrap">
  <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
            <p class="copyRight mb-0 mt-1 text-center">©{{currentYear}} {{company.name}} <span class="ml-2 mr-2">-</span> 
                <a href="https://cte.mcu.es/politica-de-privacidad/" class="text-info" data-toggle="modal" data-target="#modal-tac">{{ "Privacy" | translate }}</a>
                <span  class="ml-2 mr-2">-</span> 
                <a href="https://wwww.culturaydeporte.gob.es/cultura/artesescenicas/proteccion-datos.html" class="text-info" data-toggle="modal" data-target="#modal-ln">{{ "Data Protection" | translate }}</a>
                <span class="ml-2 mr-2">-</span> 
                <a href="#" class="text-info" data-toggle="modal" data-target="#modal-cookie">{{ "Cookies Warning" | translate }}</a>
            </p>
        </div>

        <div class="col-md-4 text-right" *ngIf="faq.socials">
            <div class="socials">
                <a *ngIf="faq.socials.facebook" [href]="faq.socials.facebook" target="_blank" class="text-info ml-2 mr-2"><i class="fab fa-facebook-f fa-2x"></i></a>
                <a *ngIf="faq.socials.twitter" [href]="faq.socials.twitter" target="_blank" class="text-info ml-2 mr-2"><i class="fab fa-twitter fa-2x"></i></a>
                <a *ngIf="faq.socials.instagram" [href]="faq.socials.instagram" target="_blank" class="text-info ml-2 mr-2"><i class="fab fa-instagram fa-2x"></i></a>
                <a *ngIf="faq.socials.youtube" [href]="faq.socials.youtube" target="_blank" class="text-info ml-2 mr-2"><i class="fab fa-youtube fa-2x"></i></a>
                <a *ngIf="faq.socials.linkedin" [href]="faq.socials.linkedin" target="_blank" class="text-info ml-2 mr-2"><i class="fab fa-linkedin-in fa-2x"></i></a>
                <a *ngIf="faq.socials.twitch" [href]="faq.socials.twitch" target="_blank" class="text-info ml-2 mr-2"><i class="fab fa-twitch fa-2x"></i></a>
                <a *ngIf="faq.socials.web" [href]="faq.socials.web" target="_blank" class="text-info ml-2 mr-2"><i class="fas fa-globe fa-2x"></i></a>
           
         
           
                
            </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-auto my-3">
            <img class="logo-footer" src="assets/images/logo-footer.png" alt="" srcset="">
        </div>
      </div>
  </div>
   <!-- footer-wrap close -->
</footer>
<!-- footer close -->

<!-- TAC Modal -->
<div class="modal fade" id="modal-tac" tabindex="-1" role="dialog" aria-labelledby="Términos y Condiciones" aria-hidden="true">
    <div class="modal-width modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="border-radius: 8px;">
            <div class="modal-header">
                <h4 class="mb-0">{{ "Terms and Conditions" | translate }}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                {{company.term_and_conditions}}
            </div>
        </div>
    </div>
</div>

<!-- Legal Notice Modal -->
<div class="modal fade " id="modal-ln" tabindex="-1" role="dialog" aria-labelledby="Aviso Legal" aria-hidden="true">
    <div class="modal-width modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="border-radius: 8px;">
            <div class="modal-header">
                <h4 class="mb-0">{{ "Legal Notice" | translate }}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                {{company.legal_notice}}
            </div>
        </div>
    </div>
</div>

<!-- Cookie Notice Modal -->
<div class="modal fade " id="modal-cookie" tabindex="-1" role="dialog" aria-labelledby="Cookie Police" aria-hidden="true">
    <div class="modal-width modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="border-radius: 8px;">
            <div class="modal-header">
                <h4 class="mb-0">{{ "Cookies Warning" | translate }}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                {{company.cookies_warning}}
            </div>
        </div>
    </div>
</div>
