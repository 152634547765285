import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild,} from '@angular/core';
import {CoursesService} from 'src/app/Services/Courses.service';
import * as Player from '@vimeo/player/dist/player.js';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from 'src/app/Services/Auth/auth.service';


declare var $;

@Component({
  selector: 'app-lessonbox',
  templateUrl: './lesson-box.component.html',
  styleUrls: ['./lesson-box.component.scss'],
})
export class LessonBoxComponent implements OnInit {
  @ViewChild('closeModal') closeModal: ElementRef<HTMLInputElement>;

  @Input() lesson;
  @Input() textLesson = 'Unit';
  @Input() openBox = false;
  @Input() isLast = false;
  @Input() available = false;
  @Input() unavailableMsg = 'Para acceder al contenido de las lecciones debe comprar el curso';

  videoPicture;
  lessonImage;
  videoFrame;
  videoId;
  videoPicture2;
  videoId2;
  videoPicture3;
  videoId3;
  videoPicture4;
  videoId4;
  videoPicture5;
  videoId5;
  videoPicture6;
  videoId6;
  videoLoaded = false;
  isLessonBoxShown = false;
  lessonLetter = 'U';

  @Output() boxExpanded = new EventEmitter<string>();
  @Output() allDone = new EventEmitter<boolean>();
  @Output() done = new EventEmitter<number>();

  constructor(
    private courseService: CoursesService,
    private router: Router,
    private toaster: ToastrService,
    public authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.getThumb();
    this.getThumb2();
    this.getThumb3();
    this.getThumb4();
    this.getThumb5();
    this.getThumb6();
    if (this.openBox) {
      this.lesson.expand = true;
    }
    this.lessonImage = this.lesson?.image?.medium;
    this.lessonLetter = this.textLesson.charAt(0);
  }

  getThumb() {
    if (this.lesson.video) {
      this.courseService
        .getVideoData(this.lesson.video)
        .then((data) => {

          this.videoPicture = data.body.pictures?.sizes[3].link;
          this.videoId = data.body.uri?.replace('/videos/', '');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  getThumb2() {
    if (this.lesson.video2) {
      this.courseService
        .getVideoData(this.lesson.video2)
        .then((data) => {
          this.videoPicture2 = data.body.pictures?.sizes[3].link;
          this.videoId2 = data.body.uri?.replace('/videos/', '');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  getThumb3() {
    if (this.lesson.video3) {
      this.courseService
        .getVideoData(this.lesson.video3)
        .then((data) => {
          this.videoPicture3 = data.body.pictures?.sizes[3].link;
          this.videoId3 = data.body.uri?.replace('/videos/', '');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  getThumb4() {
    if (this.lesson.video4) {
      this.courseService
        .getVideoData(this.lesson.video4)
        .then((data) => {
          this.videoPicture4 = data.body.pictures?.sizes[3].link;
          this.videoId4 = data.body.uri?.replace('/videos/', '');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  getThumb5() {
    if (this.lesson.video5) {
      this.courseService
        .getVideoData(this.lesson.video5)
        .then((data) => {
          this.videoPicture5 = data.body.pictures?.sizes[3].link;
          this.videoId5 = data.body.uri?.replace('/videos/', '');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  getThumb6() {
    if (this.lesson.video6) {
      this.courseService
        .getVideoData(this.lesson.video6)
        .then((data) => {
          this.videoPicture6 = data.body.pictures?.sizes[3].link;
          this.videoId6 = data.body.uri?.replace('/videos/', '');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  toggleVisibility() {
    if (!this.available) {
      this.toaster.error(this.unavailableMsg);
      return;
    }
    if (!this.lesson.expand) {
      this.boxExpanded.emit(this.lesson.id);
    } else {
      this.lesson.expand = false;
    }
  }

  initializeVideoPlayer(videoID) {
    const player = new Player(videoID, {
      id: videoID,
      responsive: true
    });

    player.on('ended', () => {
      // this.changeLessonStatus(true);
    });

    player.on('loaded', () => {
      const padding = player.element.parentNode.style.paddingTop.replace('%', '');
      if (padding > 75) {
        player.element.parentNode.style.paddingTop = '75%';
      }
      this.videoLoaded = true;
    });

    $('#modal-' + videoID).on('hide.bs.modal', () => {
      player.unload();
      this.videoLoaded = false;
    });
  }

  openVideoModal(videoID) {
    this.videoLoaded = true;

    setTimeout(() => {
      this.initializeVideoPlayer(videoID);
    }, 200);
  }

  changeLessonStatus(status) {
    if (status) {
      this.done.emit(this.lesson.id);
    }
    //if not loger and free course then no need to call
    let user = this.authService.user;
    if (user) {
      this.courseService
        .changeLessonStatus(status, this.lesson.id)
        .then((data) => {
          this.lesson.approved = status;
          this.lesson.expand = false;
          this.isLessonBoxShown = false;

          if (this.closeModal) {
            this.closeModal.nativeElement.click();
          }

          if (data.status === 'finished') {
            if (data.extra !== null) {
              this.allDone.emit(true);
              // this.router.navigateByUrl( "/courses/review/" + this.lesson.course + "?extra=" + data.extra.id );
            } else {
              this.allDone.emit(false);
              // this.router.navigateByUrl("/courses/review/" + this.lesson.course);
            }
          } else if (data.extra !== null) {
            this.router.navigateByUrl('/courses/extra/' + data.extra.id);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.lesson.approved = true;
    }
  }
}
