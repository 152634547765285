import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { LoadingBarModule } from "@ngx-loading-bar/core";

import { AppComponent } from "./app.component";
import { MainComponent } from "./Main/Main.component";
import { HomeComponent } from "./Pages/Home/Home.component";
import { AppRoutingModule } from "./app-routing.module";
import { WidgetsModule } from "./Widgets/Widgets.module";
import { MenuComponent } from "./Layouts/Menu/HeaderMenu/HeaderMenu/HeaderMenu.component";
import { HeaderOneComponent } from "./Layouts/Header/HeaderOne/HeaderOne.component";
import { TemplateModule } from "./Template/Template.module";
import { FooterOneComponent } from "./Layouts/Footer/FooterOne/FooterOne.component";
import { FooterMenuComponent } from "./Layouts/Menu/FooterMenu/FooterMenu/FooterMenu.component";
import { MenuItems } from "./Core/Menu/MenuItems/MenuItems";
import { DropletService } from "./Services/Droplet.service";
import { StickyheaderComponent } from "./Layouts/Header/StickyHeader/StickyHeader.component";
import { SessionModule } from "./Pages/Session/Session.module";
import { GuardService } from "./Services/Auth/guard.service";
import { ApiService } from "./Services/Api.service";
import { StatesService } from "./Services/states.service";
import { PublicGuardService } from "./Services/Auth/publicGuard.service";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxBarcodeModule } from "ngx-barcode";
import { InitProvider } from "./init-provider";
import { MeetComponent } from "./meet/meet.component";
import { RegistrationAllowedService } from "./Services/Auth/registrationAlowed.service";
import { ToastrModule } from "ngx-toastr";
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
} from "ngx-cookieconsent";

import { PipesModule } from "./Pipes/Pipes.module";
import { TeacherProfileComponent } from "./Pages/Teachers/teacher-profile/teacher-profile.component";
import { TeacherListComponent } from "./Pages/Teachers/teacher-list/teacher-list.component";
import { TeachersModule } from "./Pages/Teachers/teachers.module";
import { InfiniteScrollModule } from "ngx-infinite-scroll";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function initProviderFactory(provider: InitProvider) {
  return () => provider.load();
}

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: "localhost", // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: "#000",
    },
    button: {
      background: "#f1d600",
    },
  },
  type: "opt-in",
  revokable: false,
  // "type": "info",
  content: {
    dismiss: "Acepto",
    deny: "No Acepto",
    link: "Mas info",
    href: "?ll=1",
    policy: "Politica de cookies3333",
    close: "&#x274c;",
    target: "_blank",
  },
};

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HomeComponent,
    MenuComponent,
    HeaderOneComponent,
    FooterOneComponent,
    FooterMenuComponent,
    StickyheaderComponent,
    MeetComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    WidgetsModule,
    TemplateModule,
    HttpClientModule,
    SessionModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxBarcodeModule,
    ToastrModule.forRoot({
      positionClass: "toast-bottom-right",
    }),
    //  NgcCookieConsentModule
    NgcCookieConsentModule.forRoot(cookieConfig),
    PipesModule,
    InfiniteScrollModule,
  ],
  providers: [
    MenuItems,
    DropletService,
    ApiService,
    StatesService,
    GuardService,
    PublicGuardService,
    RegistrationAllowedService,
    InitProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: initProviderFactory,
      deps: [InitProvider],
      multi: true,
    },
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
