import { NgModule } from '@angular/core';
import { CardMaskPipe } from './char-mask.pipe';
import { DurationPipe } from './duration.pipe';
import { MyCurrencyPipe } from './currency.pipe';
import { SrcsetPipe } from './srcset.pipe';
import { DatePipe } from './date.pipe';


@NgModule({
  declarations: [
    CardMaskPipe,
    DurationPipe,
    MyCurrencyPipe,
    SrcsetPipe,
    DatePipe
  ],
  exports: [
    CardMaskPipe,
    DurationPipe,
    MyCurrencyPipe,
    SrcsetPipe,
    DatePipe
  ]
})
export class PipesModule { }
