<div class="row">
  <div class="col">
    <div class="session-content text-center card">
      <div class="mb-2">
        <h5 class="text-capitalize font-weight-bold mb-4">
          {{ "Login" | translate }}
        </h5>
      </div>
      <div>
        <form [formGroup]="form" class="mb-2">
          <div class="form-group text-left icon-form">
            <input
              name="name-input"
              class="form-control custom-input"
              type="email"
              value=""
              placeholder="Email"
              formControlName="email"
            />
            <img
              src="assets/images/mootiva/mail.svg"
              loading="lazy"
              decoding="async"
            />

            <small
              class="text-danger"
              *ngIf="
                form.controls['email'].hasError('required') &&
                form.controls['email'].touched
              "
            >
              {{ "You must enter an email" | translate }}
            </small>
            <small
              class="text-danger"
              *ngIf="
                form.controls['email'].errors &&
                !form.controls['email'].hasError('required') &&
                form.controls['email'].touched
              "
            >
              {{ "You must enter an valid email" | translate }}
            </small>
          </div>
          <div class="form-group text-left icon-form">
            <input
              name="name-input"
              class="form-control custom-input"
              type="password"
              value=""
              [placeholder]="'Password' | translate"
              formControlName="password"
            />
            <img
              src="assets/images/mootiva/pass.svg"
              loading="lazy"
              decoding="async"
            />
            <small
              class="text-danger"
              *ngIf="
                form.controls['password'].hasError('required') &&
                form.controls['password'].touched
              "
            >
              {{ "You must enter a password" | translate }}
            </small>
          </div>
          <small class="text-danger" *ngIf="showLoginError">
            {{ "Invalid credentials" | translate }}
          </small>
          <div class="form-group mt-5">
            <button
              class="btn btn-info"
              [class.disabled]="loading"
              (click)="logIn()"
            >
              {{ "Enter" | translate }}
              <i class="fa fa-circle-notch fa-spin ml-1" *ngIf="loading"></i>
            </button>
          </div>
        </form>

        <div class="row" *ngIf="company.allow_registration">
          <div class="col-12">
            <p class="mb-0">
              <span class="text-muted">{{
                "You do not have an account yet?" | translate
              }}</span>

              <a
                *ngIf="view_plans_logout"
                [state]="{ ignoreLoadingBar: true }"
                target="_self"
                class="font-weight-bold ml-2 register"
                (click)="goToRegister()"
                fragment="pricing"
              >
                {{ "Sign Up" | translate }}
              </a>
              <a
                *ngIf="!view_plans_logout"
                class="font-weight-bold ml-2 register"
                (click)="goToRegister()"
              >
                {{ "Sign Up" | translate }}
              </a>
            </p>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-12">
            <a class="font-weight-bold" [routerLink]="['/forgot']">{{
              "I forgot my password" | translate
            }}</a>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>
