import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { InitProvider } from "src/app/init-provider";
import { AuthService } from "src/app/Services/Auth/auth.service";
import { environment } from "src/environments/environment";
import {ApiService} from '../../Services/Api.service';
import {CoursesService} from 'src/app/Services/Courses.service';


@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  @Output() changeView = new EventEmitter<any>();
  @Input() isPurchasing: boolean;
  @Input() courseId: string;
  @Input() price: number;
  @Input() eventId: string;
  @Input() inscriptionId: string;
  
  public form: FormGroup;
  emailPattern = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
  showLoginError = false;
  loading = false;
  passwordRecoveryUrl = environment.baseUrl + "/password/reset";
  company: any;
  view_plans_logout = false;

  constructor(
    private apiService: ApiService,
    private coursesService: CoursesService,

    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private initProvider: InitProvider,
    private activatedRoute: ActivatedRoute
  ) {
    this.company = initProvider.getSettings();
    this.passwordRecoveryUrl = this.company.base_url + "/password/reset";
  }

  ngOnInit() {
    this.view_plans_logout = this.company.view_plans_logout;

    this.form = this.fb.group({
      email: ["", [Validators.required, Validators.pattern(this.emailPattern)]],
      password: ["", Validators.required],
    });
  }

  // login method is used when form field is valid then router will be navigate to the home page.
  async logIn() {
    let self=this;
    if (this.form.valid) {
      this.loading = true;
      try {
        await this.authService.logIn(
          this.form.get("email").value,
          this.form.get("password").value
        );
        window.location.href = "/programs/inscription/"+this.inscriptionId+"/"+this.courseId;

    
      } catch (error) {
        this.loading = false;
        console.log(error);
        this.showLoginError = true;
      }
    } else {
      for (const i in this.form.controls) {
        this.form.controls[i].markAsTouched();
      }
    }
  }
  public goToRegister() {
    this.changeView.emit("register");
  }
}
