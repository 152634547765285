<div class="session-content text-center card">
  <div class="mb-2">
    <h5 class="font-weight-bold mb-4">
      {{ "Crear una cuenta o iniciar sesión" | translate }}
    </h5>
  </div>
  <div>
    <form [formGroup]="form" class="mb-2">
      <div class="form-group text-left icon-form">
        <input
          name="name-input"
          class="form-control custom-input"
          type="text"
          value=""
          [placeholder]="'Full name' | translate"
          formControlName="firstName"
        />
        <img style="height: 18px" src="assets/images/mootiva/user.svg" />

        <div class="error-container">
          <small
            class="text-danger"
            *ngIf="
              form.controls['firstName'].hasError('required') &&
              form.controls['firstName'].touched
            "
          >
            {{ "You must enter a first and last name" | translate }}
          </small>
          <small
            class="text-danger"
            *ngIf="
              form.controls['firstName'].hasError('fullNameRequired') &&
              form.controls['firstName'].touched
            "
          >
            {{ "You must enter a first and last name" | translate }}
          </small>
        </div>
      </div>

      <div class="form-group text-left icon-form">
        <input
          name="name-input"
          class="form-control custom-input"
          type="email"
          value=""
          [placeholder]="'E-mail' | translate"
          formControlName="email"
        />
        <img src="assets/images/mootiva/mail.svg" />
        <div class="error-container">
          <small
            class="text-danger"
            *ngIf="
              form.controls['email'].hasError('required') &&
              form.controls['email'].touched
            "
          >
            {{ "You must enter an email" | translate }}
          </small>
          <small
            class="text-danger"
            *ngIf="
              form.controls['email'].errors &&
              !form.controls['email'].hasError('required') &&
              form.controls['email'].touched
            "
          >
            {{ "You must enter a valid email" | translate }}
          </small>
        </div>
      </div>
      <div class="form-group text-left icon-form">
        <input name="name-input" class="form-control custom-input" type="text" value="" placeholder="Dni" formControlName ='dni'>
        <img class="dni" src="assets/images/mootiva/address-card-solid.svg">
        <div class="error-container">
            <small class="text-danger text-left" *ngIf="form.controls['dni'].hasError('required') && form.controls['dni'].touched">
               
                {{ "You must include a valid dni" | translate }}
            </small>
        </div>
     </div>
      <div class="form-group text-left icon-form">
        <input
          name="name-input"
          class="form-control custom-input"
          type="password"
          value=""
          [placeholder]="'Password' | translate"
          formControlName="newPassword"
        />
        <img src="assets/images/mootiva/pass.svg" />
        <div class="error-container text-danger">
          <ng-container
            *ngIf="
              form.controls['newPassword'].touched &&
              !form.controls['newPassword'].valid
            "
          >
            <small *ngIf="form.controls['newPassword'].hasError('required')">
              {{ "Password is required" | translate }}
            </small>
            <small *ngIf="form.controls['newPassword'].hasError('minlength')">
              {{ "Password must be at least 6 characters long" | translate }}
            </small>
          </ng-container>
        </div>
      </div>

      <div class="form-group text-left icon-form">
        <input
          name="name-input"
          class="form-control custom-input"
          type="password"
          value=""
          [placeholder]="'Confirm password' | translate"
          formControlName="confirmPassword"
        />
        <div class="error-container text-danger">
          <ng-container
            *ngIf="
              form.controls['confirmPassword'].touched &&
              !form.controls['confirmPassword'].valid
            "
          >
            <small
              *ngIf="form.controls['confirmPassword'].hasError('required')"
            >
              {{ "Password is required" | translate }}
            </small>
            <small
              *ngIf="form.controls['confirmPassword'].hasError('minlength')"
            >
              {{ "Password must be at least 6 characters long" | translate }}
            </small>
            <small
              *ngIf="form.controls['confirmPassword'].hasError('MatchPassword')"
            >
              {{ "Passwords do not match" | translate }}
            </small>
          </ng-container>
        </div>
      </div>

      <div class="form-group">
        <input
          class="checkbox-custom"
          type="checkbox"
          value=""
          id="termscheck"
          formControlName="tac"
        />
        <label class="checkbox-custom-label" for="termscheck">
          {{ "I accept the" | translate }}
          <a
            href="#"
            class="font-weight-bold"
            data-toggle="modal"
            data-target="#modal-tac"
            >{{ "Terms and Conditions" | translate }}</a
          >
        </label>
        <div
          class="error-container text-danger"
          *ngIf="form.controls['tac'].touched && !form.controls['tac'].valid"
        >
          <small *ngIf="form.controls['tac'].hasError('required')">
            Debes aceptar los términos y condiciones
          </small>
        </div>
      </div>

      <small class="text-danger" *ngIf="showRegisterError">
        {{ registerErrorMessage }}
      </small>

      <div class="form-group mt-3 text-center">
        <button
          [disabled]="loading"
          class="btn btn-info"
          href="javascript:void(0)"
          (click)="signUp()"
        >
          {{ "Create account" | translate }}
         <i class="fa fa-circle-notch fa-spin ml-1" *ngIf="loading"></i>

        </button>
      </div>
    </form>

    <div class="row">
      <div class="col-12">
        <p class="mb-0">
          <span class="text-muted">{{ "Already a member?" | translate }}</span>
          <a class="font-weight-bold ml-2 cursor-pointer" (click)="goToLogin()">
            {{ "Sign In" | translate }}
          </a>
        </p>
      </div>
    </div>
  </div>
</div>
