import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {
  @Input() src: any;
  @Input() height: any = 'auto';
  @Input() width: any = '100%';
  @Input() alt: any;
  @Input() loading = 'eager';
  webp: any;
  png: any;
  jpg: any;
  originalExtension: any;

  constructor() {
  }

  ngOnInit() {
    this.originalExtension = this.src.split('.').pop();
    if (this.originalExtension === 'png') {
      this.png = this.src.replace(/\.[^/.]+$/, '.png');
    }

    if (this.originalExtension === 'jpg' || this.originalExtension === 'jpeg') {
      this.jpg = this.src.replace(/\.[^/.]+$/, '.jpg');
    }

    this.webp = this.src.replace(/\.[^/.]+$/, '.webp');
    const image = new Image();
    image.src = this.src;
    const self = this;
    image.onload = function () {
      self.height = image.height;
      self.width = image.width;
    };
  }

}
