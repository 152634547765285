import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from 'src/app/Services/Auth/auth.service';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-lets-talk',
  templateUrl: './LetsTalk.component.html',
  styleUrls: ['./LetsTalk.component.scss']
})
export class LetsTalkComponent implements OnInit {

  user: any;
  userName: string;
  userEmail: string;
  currentUrl;
  @Input() whiteText = 'false';

  constructor(private authService: AuthService, private router: Router) {
  }

  ngOnInit() {
    this.currentUrl = this.router.url;
    this.user = this.authService.user;
    // if (this.user){
    //   this.userEmail= this.user.email.length<26?this.user.email:(this.user.email.slice(0,25)+'...');
    //   this.userName = this.authService.user.first_name+' '+ this.authService.user.last_name;
   
    // }


    if (this.user) {
      this.userEmail =
      this.user.email.length < 24
      ? this.user.email
      : this.user.email.slice(0, 23) + "...";
      
      this.userName =
      this.user.first_name +
      " " +
      this.user.last_name;
      
      console.log('userName',this.userName.length)
      this.userName =
        this.userName.length < 24
          ? this.userName
          : this.userName.slice(0, 22) + "...";
    }
    console.log('userName',this.userName)


      //this.userName = this.authService.user ? (this.authService.user.first_name +' '+ this.authService.user.last_name): false;
    

    // if (!this.userName && this.authService.user) {
    //   // this.authService.logOutWithRedirect()
    // }
    this.authService.userData.subscribe((data)=>{
      if (data){
        this.userEmail= data.email.length<26?data.email:(data.email.slice(0,25)+'...');
        this.userName = data.first_name+' '+ data.last_name;
        this.userName =
        this.userName.length < 24
          ? this.userName
          : this.userName.slice(0, 22) + "...";
    
        this.user.hero_image.small = data.hero_image.small
      }
      
    } )


    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
  }

  logOut() {
    this.authService.logOut();
    window.location.href = '/home';
  }

}
