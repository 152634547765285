import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Pipe({name: 'duration'})
export class DurationPipe implements PipeTransform {
  constructor( private translate: TranslateService) {}
  transform( value: number ): string {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;
    const strHours = this.translate.instant('hour').concat(hours > 1 ? 's' : '');

    return (hours > 0 ? hours + ' ' + strHours + ' ' : '') + (minutes ? minutes + ' min' : '');
  }
}
