import { Injectable } from '@angular/core';
import { ApiService } from './Api.service';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  eventsUrl = 'events';
  publicUrl = 'public/events'

  constructor(private apiService: ApiService) { }

  async getListWithFilters(filters = '', page = 1) {
    try {
      const events = await this.apiService.get(this.eventsUrl.concat('?page=' + page).concat(filters));
      return events;
    } catch (error) {
      return error;
    }
  }

  async getEventById(id) {
    try {
      const event = await this.apiService.get(this.eventsUrl.concat('/').concat(id));
      return event;
    } catch (error) {
      return error;
    }
  }

  async getPublicById(eventId) {
    try {
      const event = await this.apiService.get(this.publicUrl.concat('/').concat(eventId));
      return event;
    } catch (error) {
      return error;
    }
  }

  async register(eventId) {
    try {
      const event = await this.apiService.post(this.eventsUrl.concat('/').concat(eventId).concat('inscriptions'), {});
      return event;
    } catch (error) {
      return error;
    }
  }

  async getPublicListWithFilters(filters = '', page = 1) {
    try {
      const events = await this.apiService.get(this.publicUrl.concat('?page=' + page).concat(filters));
      return events;
    } catch (error) {
      return error;
    }
  }
}
