import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ApiService } from "src/app/Services/Api.service";
import { AuthService } from "src/app/Services/Auth/auth.service";
import { InitProvider } from "../../../init-provider";

@Component({
  selector: "app-signup-form",
  templateUrl: "./SignUpForm.component.html",
  styleUrls: ["./SignUpForm.component.scss"],
})
export class SignUpFormComponent implements OnInit {
  public form: FormGroup;
  emailPattern = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
  loading = false;
  showRegisterError = false;
  showRegisterErrorDni = false;
  showRegisterEmail = false;

  registerErrorMessage = "Hubo un error en el registro";
  registerErrorMessageDni = "El campo dni es obligatorio";
  public company: any;
  categories = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    initProvider: InitProvider,
    private apiService: ApiService
  ) {
    this.company = initProvider.getSettings();
  }

  ngOnInit() {
    this.form = this.fb.group(
      {
        firstName: ["", Validators.required],
        email: [
          "",
          [Validators.required, Validators.pattern(this.emailPattern)],
        ],
        dni: ["", Validators.required],
        newPassword: ["", [Validators.required, Validators.minLength(6)]],
        confirmPassword: ["", [Validators.required, Validators.minLength(6)]],
        tac: ["", [Validators.requiredTrue]],
      },
      {
        validator: this.MatchPassword, // your validation method
      }
    );
  }

  /*
   * Match Password Custom validation
   */
  MatchPassword(AC: AbstractControl) {
    const password = AC.get("newPassword").value; // to get value in input tag
    const confirmPassword = AC.get("confirmPassword").value; // to get value in input tag
    if (password != confirmPassword) {
      AC.get("confirmPassword").setErrors({ MatchPassword: true });
    } else {
      return null;
    }
  }

  async signUp() {
    if (this.form.valid) {
      this.loading = true;
      this.showRegisterError = false;
      this.showRegisterErrorDni = false;
      this.showRegisterEmail = false;
      const nameArray = this.form.get("firstName").value.trim().split(" ");
      if (nameArray.length < 2) {
        this.loading = false;
        return this.form.controls["firstName"].setErrors({
          fullNameRequired: true,
        });
      } else {
        let firstNames = "";
        let lastName = "";
        nameArray.map((name, i) => {
          if (i != nameArray.length - 1) {
            firstNames = firstNames.concat(name + " ");
          } else {
            lastName = name;
          }
        });
        try {
          await this.authService.register(
            firstNames,
            lastName,
            this.form.get("email").value,
            this.form.get("newPassword").value,
            this.form.get("dni").value
          );

          // se setean las categorias por default para evitar el qust inicial
          await this.setDefaultCategories();
          this.loading = false;
          return (window.location.href = "/home");
        } catch (error) {
         
         
          this.loading=false;
          // this.showRegisterError = true;
          // this.showRegisterErrorDni = true;
         

          if (error.error.errors["email"]) {
            this.registerErrorMessage = error.error.errors["email"][0];
            this.showRegisterError = true;
          }
          if (error.error.errors["dni"]) {
            this.registerErrorMessageDni = error.error.errors["dni"][0];
            this.showRegisterErrorDni = true;
          }
          if (!error.error.errors["dni"]&&!error.error.errors["email"]){
            this.registerErrorMessage = 'Error in register';
            this.showRegisterError = true;
          }
        }
      }
    } else {
      this.loading = false;
      for (const i in this.form.controls) {
        this.form.controls[i].markAsTouched();
      }
    }
  }

  async setDefaultCategories() {
    const categoriesApi = await this.apiService.get("categories?from_quizzes");
    this.categories = categoriesApi.data.filter(
      (c) => c.parent == null || c.parent.length <= 0
    );

    const options = {
      id: this.authService.user.id,
      category_id: null,
      category_2_id: null,
      category_3_id: null,
      availability: 1,
    };
    this.categories.map((c) => {
      if (!options.category_id) {
        options.category_id = c.id;
        return;
      }
      if (!options.category_2_id) {
        options.category_2_id = c.id;
        return;
      }
      if (!options.category_3_id) {
        options.category_3_id = c.id;
        return;
      }

      return;
    });

    const saved = await this.apiService.put(
      "users/" + this.authService.user.id,
      options
    );

    const s = await this.authService.saveUserData();
  }
}
