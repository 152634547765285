import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {InitProvider} from '../../../init-provider';
import {AuthService} from '../../../Services/Auth/auth.service';

/*
 * Menu interface
 */
export interface Menu {
  state: any;
  name?: string;
  type?: string;
  icon?: string;
  children?: ChildrenItems[];
  id?: string
}

/*
 * Children menu interface
 */
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}


@Injectable()
export class MenuItems {
  HEADERMENUITEMS = [
    {
      state: 'top',
      name: 'Home',
      type: 'custom',
      fragment: 'home'
    },
    /*{
      state: "home",
      name: this.translate.instant("Method"),
      type: "fragment",
      fragment: "metodologia"
    },
    {
      state: "home",
      name: this.translate.instant("Courses"),
      type: "fragment",
      fragment: "cursos"
    },
    {
      state: "home",
      name: this.translate.instant("Pricing"),
      type: "fragment",
      fragment: "planos"
    },
    {
      state: "home",
      name: this.translate.instant("Faqs"),
      type: "fragment",
      fragment: "faqs"
    }*/
  ];

  AUTHITEMS = [
    {
      state: ['logged', 'home'],
      name: 'Home',
      type: 'nested'
    },
    // {
    //   state: ['events', 'list'],
    //   name: 'Events',
    //   type: 'nested',
    //   key: 'events',
    // },
    // {
    //   state: ['courses', 'aula'],
    //   name: 'Courses2',
    //   type: 'nested',
    //   key: 'courses'
    // },
    {
      state: ['programs', 'list'],
      name: 'Cursos',
      type: 'nested',
      key: 'programs'
    },
    {
      state: ['teachers', 'list'],
      name: 'Teachers',
      type: 'nested',
      key: 'teachers',
    },
    {
      state: ['contests', 'list'],
      name: 'Contests',
      type: 'nested',
      key: 'contests',
    },
   
    {
      state: ['logged', 'faqs'],
      name: 'Support',
      type: 'mobile'
    },
    {
      state: ['logged', 'profile'],
      name: 'Profile',
      type: 'mobile'
    },
  ];
  course_text = 'Course';
  lesson_text = 'Unit';
  course_text_s;
  lesson_text_s;
  private company: any;
  private currentUser: any;

  constructor(
    public translate: TranslateService,
    private initProvider: InitProvider,
    private authService: AuthService
  ) {
    this.currentUser = this.authService.user;
    this.company = this.initProvider.getSettings();
    this.disableMenus();
    this.setTeachersMenu();
  }

  /*
   * Get all header menu
   */
  getMainMenu(): Menu[] {
    return this.HEADERMENUITEMS;
  }

  getAuthMenu(): Menu[] {

    return this.AUTHITEMS;
  }

  setMenuCourseName(name) {
    const coursesMenu = this.AUTHITEMS.find(x => x.key === 'courses');
    if (coursesMenu) {
      coursesMenu.name = name;
    }
  }

  private setTeachersMenu() {
    const teachersMenu = this.AUTHITEMS.find(item => item.key === 'teachers');
    const language = this.currentUser ? this.currentUser.language : this.company.language;
    if (teachersMenu) {
      teachersMenu.name = language === 'en'
        ? (this.company.teachers_menu_en ? this.company.teachers_menu_en : 'Teachers')
        : (this.company.teachers_menu_es ? this.company.teachers_menu_es : 'Profesores');
    }
  }

  private disableMenus() {
    if (!this.company.has_courses) {
      this.AUTHITEMS.splice(this.AUTHITEMS.findIndex(item => item.key === 'courses'), 1);
    }
    if (!this.company.has_events) {
      this.AUTHITEMS.splice(this.AUTHITEMS.findIndex(item => item.key === 'events'), 1);
    }
    if (!this.company.has_teachers) {
      this.AUTHITEMS.splice(this.AUTHITEMS.findIndex(item => item.key === 'teachers'), 1);
    }
    if (!this.company.has_contests) {
      this.AUTHITEMS.splice(this.AUTHITEMS.findIndex(item => item.key === 'contests'), 1);
    }
  }
}
