import {Component, Input, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {InitProvider} from 'src/app/init-provider';
import {MenuItems} from '../../../../Core/Menu/MenuItems/MenuItems';
import {AuthService} from 'src/app/Services/Auth/auth.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './HeaderMenu.component.html',
  styleUrls: ['./HeaderMenu.component.scss']
})

export class MenuComponent implements OnInit {

  @Input() stickyClass: any;
  currentUrl: any;
  items: any;


  constructor(
    public menuItems: MenuItems,
    private router: Router,
    public authService: AuthService,
    private initProvider: InitProvider,
  ) {
  }

  ngOnInit() {
    this.currentUrl = this.router.url;
    const company = this.initProvider.getSettings();
    const json = JSON.parse(company.faq_json);
    const user = this.authService.user;
    let user_language;
    if (user) {
      user_language = user.language;
    } else {
      user_language = company.language;
    }
    let course_text;
    let course_text_s;

    if (user_language === 'en') {
      course_text = json.texts?.course_en ? json.texts.course_en : 'Course';
    }
    if (user_language === 'es') {
      course_text = json.texts?.course_es ? json.texts.course_es : 'Course';
    }
    if (user_language === 'it') {
      course_text = json.texts?.course_it ? json.texts.course_it : 'Course';
    }
    course_text_s = course_text + 's';


    this.menuItems.setMenuCourseName(course_text_s);
    if (this.authService.isAuthenticated()) {
      this.items = this.menuItems.getAuthMenu();
    } else {
      this.items = this.menuItems.getMainMenu();
    }


    // header responsive
    if ((this.currentUrl !== '/session/comingsoon') && (this.currentUrl !== '/session/maintenance')) {
      const menuItems = document.getElementsByClassName('menu-item-has-child');
      for (let i = 0; i < menuItems.length; i++) {
        (function (index) {
          menuItems[index].addEventListener('click', function (event) {
            event.stopPropagation();
            if (menuItems[index] !== undefined) {
              menuItems[index].classList.toggle('opened-submenu');
            }
          });
        })(i);
      }
      this.router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          this.currentUrl = this.router.url;
          if (this.currentUrl === '/login') {
            window.scrollTo({top: 0, behavior: 'smooth'});
          }
        }
      });
    }
  }

  logOut() {
    this.authService.logOut();
    window.location.href = '/login';
  }
}
