
<form #reviewForm="ngForm" (ngSubmit)="onSubmit(reviewForm.value)">
    <div class="question font-weight-bold border-bottom p-3 pl-5">¿Te gustó este curso?</div>



    <div class="p-3 pl-5">

        <div>
            <input id="radio-1" class="radio-custom" name="liked" value=5 [(ngModel)]="liked" type="radio"
                >
            <label for="radio-1" class="radio-custom-label">Si, muy satisfecho/a</label>
        </div>
        <div>
            <input id="radio-2" class="radio-custom" name="liked" value=4 [(ngModel)]="liked" type="radio">
            <label for="radio-2" class="radio-custom-label">Si, satisfecho/a</label>
        </div>
        <div>
            <input id="radio-3" class="radio-custom" name="liked" value=3 [(ngModel)]="liked" type="radio">
            <label for="radio-3" class="radio-custom-label">Mas o menos</label>
        </div>
        <div>
            <input id="radio-4" class="radio-custom" name="liked" value=2 [(ngModel)]="liked" type="radio">
            <label for="radio-4" class="radio-custom-label">No, poco satisfecho/a</label>
        </div>
        <div>
            <input id="radio-5" class="radio-custom" name="liked" value=1 [(ngModel)]="liked" type="radio">
            <label for="radio-5" class="radio-custom-label">No, insatisfecho/a</label>
        </div>
    </div>



    <div class="question font-weight-bold border-bottom p-3 pl-5">¿Qué dificultad tuvo el curso para tí?</div>
    <div class="p-3 pl-5">
        <div>
            <input id="radio-6" class="radio-custom" name="difficulty" value=5 [(ngModel)]="difficulty" type="radio" >
            <label for="radio-6" class="radio-custom-label">Muy dificil</label>
        </div>
        <div>
            <input id="radio-7" class="radio-custom" name="difficulty" value=4 [(ngModel)]="difficulty" type="radio">
            <label for="radio-7" class="radio-custom-label">Dificil</label>
        </div>
        <div>
            <input id="radio-8" class="radio-custom" name="difficulty" value=3 [(ngModel)]="difficulty" type="radio">
            <label for="radio-8" class="radio-custom-label">Normal</label>
        </div>
        <div>
            <input id="radio-9" class="radio-custom" name="difficulty" value=2 [(ngModel)]="difficulty" type="radio">
            <label for="radio-9" class="radio-custom-label">Fácil</label>
        </div>
        <div>
            <input id="radio-10" class="radio-custom" name="difficulty" value=1 [(ngModel)]="difficulty" type="radio">
            <label for="radio-10" class="radio-custom-label">Muy fácil</label>
        </div>

    </div>
    <div class="p-3 mt-3 text-right">
        <button class="btn btn-info mr-4" type="submit" [disabled]="(difficulty==0||liked==0)">Continuar</button>
        <button class="btn btn-link mr-3 text-info" (click)="continue()">Omitir</button>
    </div>
</form>
