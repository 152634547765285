<header class="cover">
  <div class="cover--imageBackground">
    <img [src]="company.home_image.large" loading="eager" decoding="async"
         [srcset]="company.home_image | srcset" sizes="100vw">
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-lg-6 p-4 position-relative mt-5">
        <div class="transparent-bg d-none d-md-block"></div>
        <div class="row">
          <div class="col-12">
            <h3 class="cover--title text-primary mb-0 titleTextFont">{{ company.home_text_title}}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-9 col-md-6">
            <hr>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 col-lg-12">
            <p class="cover--description text-secondary mt-1">{{ company.home_text_description }}</p>
          </div>
        </div>
        <div class="row" *ngIf="company.allow_registration">
          <div class="col">


            <a *ngIf="view_plans_logout" href="home#pricing" [state]="{ ignoreLoadingBar: true }"
               class="btn btn-info btn-flex btn-click-effect mt-3">Crear cuenta</a>
            <a *ngIf="!view_plans_logout" [routerLink]="['/register']" [state]="{ ignoreLoadingBar: true }"
               class="btn btn-info btn-flex btn-click-effect mt-3">Crear cuenta</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<!-- New home -->
<div *ngIf="company.home_video_title && company.home_video_description"
     [style.background]="company.home_background_color" class="container-fluid video-container">
  <div class="container">
    <div class="row align-items-center">
      <div [ngClass]="company.home_video?'col-md-5':'col-md-12 text-center'">
        <h4 [style.color]="company.home_font_color">{{ company.home_video_title}}</h4>
        <p style="font-size: 1.3rem" [style.color]="company.home_font_color"> {{company.home_video_description}}</p>
      </div>
      <div class="col-md-7" *ngIf="company.home_video">
        <div class="thumbContainer hover-shadow" style="margin-bottom:0" (click)="openVideoModal(videoId)"
             data-toggle="modal"
             [attr.data-target]="'#modal-video'">
          <img [src]="company.home_video.thumbnail.replace('small','medium')"
               onerror="this.src='assets/images/video_brok.jpg'" loading="lazy" decoding="async">
          <div class="playContainer">
            <img class="playButton" src="assets/images/lesson/play.svg" loading="lazy" decoding="async"
                 height="45" width="45">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="slider.length>0" id="home-slider" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div *ngFor="let item of slider;let i = index" class="carousel-item" [class.active]="i===0">
      <!--add active in class-->
      <img [src]="item.image.small" [srcset]="item.image | srcset" sizes="100vw"
           class="d-block w-100" alt="" loading="lazy" decoding="async">
      <div class="carousel-caption d-md-block" style="padding:2rem" *ngIf="item.title || item.description">
        <h4 style="color:white; margin-bottom:4rem" *ngIf="item.title">{{item.title}}</h4>
        <p style="color:white; font-size: 1.3rem" *ngIf="item.description">{{item.description}}</p>
        <div class="overlay-slider-custom"></div>
      </div>
    </div>
  </div>
  <a class="carousel-control-prev" href="#home-slider" role="button" data-slide="prev">
    <!--    <span class="carousel-control-prev-icon" aria-hidden="true"></span>-->
    <div style="width:60px">
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-circle-left" role="img"
           xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
           class="svg-inline--fa fa-caret-circle-left fa-w-16 fa-7x">
        <path fill="currentColor"
              d="M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zm27.5-379.5l-123 123c-4.7 4.7-4.7 12.3 0 17l123 123c7.6 7.6 20.5 2.2 20.5-8.5V133c0-10.7-12.9-16.1-20.5-8.5z"
              class=""></path>
      </svg>
    </div>

    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#home-slider" role="button" data-slide="next">
    <!--    <span class="carousel-control-next-icon" aria-hidden="true"></span>-->
    <div style="width:60px">
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-circle-right" role="img"
           xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
           class="svg-inline--fa fa-caret-circle-right fa-w-16 fa-7x">
        <path fill="currentColor"
              d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm-27.5 379.5l123-123c4.7-4.7 4.7-12.3 0-17l-123-123c-7.6-7.6-20.5-2.2-20.5 8.5v246c0 10.7 12.9 16.1 20.5 8.5z"
              class=""></path>
      </svg>
    </div>
    <span class="sr-only">Next</span>
  </a>
</div>
<!-- End new home -->
<div *ngIf="courses?.length > 0" class="container coursesList fadeIn mt-5">
  <div class="row">
    <div class="col-12 col-sm-6 offset-sm-3 pt-5 mb-3">
      <h2 class="titleTextFont pageTitle text-center">{{ course_text_s | translate }}</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 courseItem animate__animated animate__fadeIn" *ngFor="let course of courses"
         [routerLink]="['/courses/profile', course.id]">
      <div class="itemImage hover-shadow">
        <img [src]="course.image_array.small" [srcset]="course.image_array | srcset"
             sizes="(max-width: 768px) 100vw, 33vw"
             onerror="this.src = 'assets/images/mootiva/logo-dark.png'"
             loading="lazy" decoding="async"/>
      </div>
      <div class="itemDetails">
        <p class="itemTitle hover-text-shadow" [innerHTML]="course.name">
        </p>
        <div class="row">
          <div *ngIf="show_lesson_text=='true'">
            <div *ngIf="course?.count==1" class="col"><p class="count"> ({{ course.count }} {{lesson_text | translate}}
              )</p></div>
            <div *ngIf="course?.count>1" class="col"><p class="count"> ({{ course.count }} {{lesson_text_s | translate}}
              )</p></div>
          </div>

          <div class="col" *ngIf="course.price else free">
            <p class="text-right text-info font-weight-bold"><span *ngIf="isDiscounted(course)"><span
              class="badge bg-extra text-light mr-2">{{ "Discount" | translate }}!</span> <span class="text-muted mr-2"><del>{{ course.price | currency:company.currency.code }}</del></span></span> {{ (isDiscounted(course) ? Math.round((course.price * (1 - course.discount_rate / 100)) * 100) / 100 : course.price) | currency:company.currency.code }}
            </p>
          </div>
          <ng-template #free>
            <div class="col">
              <p *ngIf="course.access_status != 'plan'"
                 class="text-right text-info font-weight-bold"> {{ "Free" | translate}} </p>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container events-list mt-5 mb-5 fadeIn" *ngIf="events?.length > 0">
  <div class="row">
    <div class="col-12 col-sm-6 offset-sm-3 mb-3">
      <h2 class="titleTextFont pageTitle text-center">{{ "Events" | translate }}</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 col-lg-4 event-card animate__animated animate__fadeIn mb-5" *ngFor="let event of events">
      <div class="card" [routerLink]="['/events/profile', event.id]">
        <div class="card-header">
          <div class="business">
            {{event.bussiness}}
            <img class="business--image" [src]="event.business?.hero_image.small" alt="Bussines" loading="lazy"
                 decoding="async">
            <div class="business--name font-weight-bold">{{event.business?.name}}</div>
          </div>
        </div>
        <img [src]="event.hero_image?.medium" [srcset]="event.hero_image | srcset"
             sizes="(max-width: 768px) 100vw, (max-width:992px) 50vw, 33vw"
             class="card-img" decoding="async" loading="lazy"
             onerror="this.src='assets/images/mootiva/logo-dark.png'"/>
        <div class="card-body">
          <div class="event-card__date">
            <span class="h5">{{ parseEventDateNumber(event) }}</span>
            <br>
            <span class="text-danger text-capitalize font-weight-bold">{{ parseEventMonth(event) }}</span>
          </div>
          <p class="event-card__schedule">{{ "Schedule" | translate }}: {{ parseEventStartTime(event) }}
            - {{ parseEventEndTime(event) }}</p>
          <p class="card-text font-weight-bold">{{ event.title }}</p>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-6 mr-auto" *ngIf="event.price">
              <p
                class="text-right text-info font-weight-bold h5"> {{ event.price | currency:company.currency.code}} </p>
            </div>
            <div class="col-6 ml-auto text-right">
              <a href="#" class="btn btn-info mb-2">{{ "Register" | translate }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="view_plans_logout"
     [style.background]="company.home_background_color?getColorRgba(company.home_background_color):null">
  <div style="position:absolute; z-index: 10; width:100%">
    <svg viewBox="0 0 1442 187" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="1"
            d="M0 0C563.136 0 878.864 0 1442 0V183C1137 220.5 935 -22.8401 568.5 124.219C324.626 222.074 190.5 183 0 124.219L0 0Z"
            fill="white"/>
    </svg>
  </div>

  <div class="container-fluid py-6 pricing-container">
    <div id="pricing" *ngIf="view_plans_logout" class="container events-list mt-5 mb-5 fadeIn">
      <div class="row">
        <div class="col-12 col-sm-6 offset-sm-3 mb-5">
          <h2 [style.color]="company.home_font_color"
              class="titleTextFont pageTitle text-center">{{ "Pricing" | translate }}</h2>
        </div>
      </div>
      <div class="row">
        <app-pricing-list id='test' logged="false" [showTrial]="hideFreePlanInHome?'hide':'show'"></app-pricing-list>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" [id]="'modal-video'" tabindex="-1"
     role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button #closeModal type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>

      </div>
      <div class="modal-body text-center">
        <img *ngIf="!videoLoaded" class="img-fluid" style="margin: auto;" src="assets/images/loading.gif"
             loading="lazy" decoding="async">
        <div [hidden]="!videoLoaded" class="playerWrap" *ngIf="videoId" [id]="videoId"></div>
      </div>
    </div>
  </div>
</div>



