<div class="contestant-row" [class.winners]="isFinished" *ngIf="show">
  <div class="contestant-profile">
    <div class="position-relative d-none d-md-block" *ngIf="isFinished">
      <img class="contestant-ribbon" src="assets/images/contest_ribbon.svg" alt="Contest Ribbon" decoding="async"
           loading="lazy" width="101" height="190">
      <span class="podium">{{ podium + 1 }}</span>
    </div>
    <div>
      <img class="contestant-picture" [src]="participant.user.hero_image.small" alt="" width="80" height="80"
           loading="lazy" decoding="async">
      <div>{{ participant.user.name }}</div>
      <div class="text-muted" *ngIf="waitingForApproval">{{ 'Waiting for approval' | translate }}</div>
      <div class="text-muted" *ngIf="votedByMe">{{ 'Your vote' | translate }}</div>
      <ng-container *ngIf="!isFinished">
        <button class="btn btn-info text-uppercase mt-3" (click)="emitLeave()"
                *ngIf="canLeave">{{ 'Leave' | translate}}</button>
        <button class="btn btn-info text-uppercase mt-3" (click)="emitVote(participant.user.id)"
                *ngIf="canVote">{{ 'Vote' | translate }}</button>
      </ng-container>
    </div>
  </div>
  <div class="images">
    <img [src]="participant.file_one.medium" alt="" width="200" height="200" loading="lazy" decoding="async"
         (click)="changeLightboxImage(participant.file_one.medium)" *ngIf="!imageIsPlaceholder(participant.file_one)">
    <img [src]="participant.file_two.medium" alt="" width="200" height="200" loading="lazy" decoding="async"
         (click)="changeLightboxImage(participant.file_two.medium)" *ngIf="!imageIsPlaceholder(participant.file_two)">
    <img [src]="participant.file_three.medium" alt="" width="200" height="200" loading="lazy" decoding="async"
         (click)="changeLightboxImage(participant.file_three.medium)"
         *ngIf="!imageIsPlaceholder(participant.file_three)">
  </div>
</div>

<div #lightbox class="lightbox" (click)="closeLightbox()">
  <div class="close-lightbox"></div>
  <img src="" alt="" #lightboxImage>
</div>
